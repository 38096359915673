<template>
    <div class="lay_container">
        <Header></Header>
        <div class="cont_m">
            <div style="padding: 20px 220px; margin: auto">
                <div class="name-head">
                    <div class="head-title name-head-title">
                        {{ $route.query.name ? $route.query.name : '' }}
                        <div></div>
                    </div>
                    <div v-if="$route.query.type == 'Person'" class="name-information">
                        <!-- <div class="information-box">
                            <h1 class="information-box-text1">Archive ID:</h1>
                            <h2 class="information-box-text">{{ $route.query.id ? $route.query.id : '' }}</h2>
                        </div> -->
                        <div class="information-box">
                            <span class="information-box-text1">Type:</span>
                            <span class="information-box-text" style="margin-left: 5px">{{ $route.query.type ? $route.query.type : '' }}</span>
                        </div>
                        <div class="information-box">
                            <span class="information-box-text1">Gender:</span>
                            <span class="information-box-text" style="margin-left: 5px">{{ $route.query.gender ? $route.query.gender : '' }}</span>
                        </div>
                        <div class="information-box">
                            <span class="information-box-text1">Late:</span>
                            <span class="information-box-text" style="margin-left: 5px">{{ $route.query.subsidiary ? 'NO' : 'YES' }}</span>
                        </div>
                    </div>
                    <div v-else class="name-information">
                        <!-- <div class="information-box">
                            <h1 class="information-box-text1">PROFILE ID:</h1>
                            <h2 class="information-box-text">{{ $route.query.id ? $route.query.id : '' }}</h2>
                        </div> -->
                        <div class="information-box">
                            <span class="information-box-text1" style="margin-left: 5px">Type:</span>
                            <span class="information-box-text">{{ $route.query.type ? $route.query.type : '' }}</span>
                        </div>

                        <div class="information-box">
                            <span class="information-box-text1" style="margin-left: 5px">SUBSIDIARY:</span>
                            <span class="information-box-text">{{ $route.query.subsidiary ? 'NO' : 'YES' }}</span>
                        </div>
                    </div>
                </div>
                <div class="head-title">OVERVIEW</div>
                <div class="name-overview">
                    <div class="name-overview-box">
                        <h1 class="head-title" style="line-height: 35px">Status</h1>
                        <div class="name-overview-box-title" style="line-height: 25px">
                            <span class="head-title" style="margin-top: -1px">
                                Wathchlist
                                <i v-if="Wathchlist.active_status == 'Active'" style="color: rgb(16, 189, 133); margin-left: 10px" class="el-icon-success"><span class="information-box-text">ACTIVE</span></i>
                                <i v-if="Wathchlist.active_status == 'InActive'" style="color: rgb(251, 80, 80); margin-left: 10px" class="el-icon-error"><span class="information-box-text">INACTIVE</span></i>
                            </span>
                            <span>
                                <span style="margin-right: 30px">LAST REVIEWED:</span>
                                {{
                                    new Date(timers).toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                    })
                                }}
                            </span>
                        </div>
                        <div class="name-overview-box-text" v-for="(item, index) in Wathchlist?.content_types" :key="index">
                            <h1 style="background-color: #fff; font-size: 15px; line-height: 25px; color: #022955">
                                {{ item.description }}
                                <i v-if="item.active_status == 'Active'" style="color: rgb(16, 189, 133); margin-left: 10px" class="el-icon-success"><span class="information-box-text">ACTIVE</span></i>
                                <i v-if="item.active_status == 'InActive'" style="color: rgb(251, 80, 80); margin-left: 10px" class="el-icon-error"><span class="information-box-text">INACTIVE</span></i>
                            </h1>
                            <div v-show="item.sub_content_types" style="padding-left: 20px" v-for="(ite, ind) in item.sub_content_types" :key="ind">
                                <div class="linheight information-box-text" style="background-color: #fff; font-size: 14px">
                                    {{ ite.description }}
                                    <i v-if="ite.active_status == 'Active'" style="color: rgb(16, 189, 133); margin-left: 10px" class="el-icon-success"><span class="information-box-text">ACTIVE</span></i>
                                    <i v-if="ite.active_status == 'InActive'" style="color: rgb(251, 80, 80); margin-left: 10px" class="el-icon-error"><span class="information-box-text">INACTIVE</span></i>
                                </div>
                                <!-- <div class="linheight">Financial Crime</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="name-overview-box" style="padding-right: 0px">
                        <h1 class="head-title" style="line-height: 35px">Country/Territory Details</h1>
                        <div v-if="$route.query.type == 'Person'" class="name-overview-box-title">
                            <div class="information-box">
                                <h1 class="information-box-text1" v-if="person?.places_of_birth">BIRTH:</h1>
                                <h2 class="information-box-text">{{ person.places_of_birth ? person.places_of_birth[0].country.descriptor : '' }}</h2>
                            </div>
                            <div class="information-box">
                                <h1 class="information-box-text1">CITIZENSHIP:</h1>
                                <h2 class="information-box-text">{{ person.country_territory_details ? person.country_territory_details.citizenship[0].descriptor : '' }}</h2>
                            </div>
                            <div class="information-box" v-if="person?.country_territory_details?.residence">
                                <h1 class="information-box-text1">RESIDENT OF:</h1>
                                <h2 class="information-box-text" v-for="(item, index) in person?.country_territory_details?.residence" :key="index">{{ item.descriptor }}</h2>
                            </div>
                            <div class="information-box" v-if="person?.country_territory_details?.jurisdiction">
                                <h1 class="information-box-text1">JURISDICTION:</h1>
                                <h2 class="information-box-text" v-for="(item, index) in person?.country_territory_details?.jurisdiction" :key="index">{{ item.descriptor }}</h2>
                            </div>
                        </div>
                        <div v-else class="name-overview-box-title">
                            <div v-if="zhuce1" class="information-box">
                                <h1 class="information-box-text1">REGISTRATION:</h1>
                                <h2 class="information-box-text">{{ zhuce1.descriptor }}</h2>
                            </div>

                            <div v-if="lianshu" class="information-box">
                                <h1 class="information-box-text1">AFFILIATION</h1>
                                <h2 class="information-box-text" v-for="(item, index) in lianshu.affiliation" :key="index">{{ item.descriptor }}</h2>
                            </div>
                        </div>
                        <h1 class="head-title" v-if="zhuce" style="line-height: 25px">Dates</h1>
                        <div v-if="zhuce" class="name-overview-box-title">
                            <div class="information-box">
                                <h1 class="information-box-text1" v-if="$route.query.type == 'Person'">BIRTH:</h1>
                                <h1 class="information-box-text1" v-if="$route.query.type == 'Entity'">REGISTRATION:</h1>

                                <h2 v-if="zhuce?.month > 0" class="information-box-text">
                                    Time:
                                    {{
                                        new Date(zhuce?.year, zhuce?.month - 1, zhuce?.day).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })
                                    }}
                                </h2>
                                <h2 v-else class="information-box-text">Time:</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="head-title">WATCHLIST</div>
                <div class="head-title" v-if="watchlisttable.length > 0">Sanction Lists</div>
                <div class="persion-table" v-if="watchlisttable.length > 0">
                    <el-table ref="treeTable" :data="watchlisttable" row-key="aaa_id" style="width: 100%" v-loading="loading">
                        <el-table-column label="NAME" width="800" :prop="showTranslate == true ? 'name' : 'name'" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div @click="setid(scope.row)" style="cursor: pointer; color: rgb(18, 144, 201)">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="FROM" prop="" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div v-if="scope.row?.from_date?.year > 0">
                                    {{
                                        new Date(scope.row?.from_date?.year, scope.row?.from_date?.month - 1, scope.row?.from_date?.day).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })
                                    }}
                                </div>
                                <div v-else>
                                 
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="TO" :prop="showTranslate == true ? 'summary' : 'TO'" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div v-if="scope.row?.to_date?.year > 0">
                                    {{
                                        new Date(scope.row?.to_date?.year, scope.row?.to_date?.month - 1, scope.row?.to_date?.day).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })
                                    }}
                                </div>
                                <div v-else>
                                
                                </div>
                            </template>
                        </el-table-column>
                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left">No Date</div>
                        </template>
                    </el-table>
                    <div class="page_i_box"></div>
                </div>
                <div class="head-title" v-if="watchlistothertable.length > 0">Other Official Lists</div>
                <div class="persion-table" v-if="watchlistothertable.length > 0">
                    <el-table ref="treeTable" :data="watchlistothertable" row-key="aaa_id" style="width: 100%" v-loading="loading">
                        <el-table-column label="NAME" width="800" :prop="showTranslate == true ? 'name' : 'name'" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div @click="setid(scope.row)" style="cursor: pointer; color: rgb(18, 144, 201)">
                                    {{ scope.row.name }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="FROM" prop="" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div v-if="scope.row?.from_date?.year > 0">
                                    {{
                                        new Date(scope.row?.from_date?.year, scope.row?.from_date?.month - 1, scope.row?.from_date?.day).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })
                                    }}
                                </div>
                                <div v-else>
                                    {{}}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="TO" :prop="showTranslate == true ? 'summary' : 'TO'" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                <div v-if="scope.row?.to_date?.year > 0">
                                    {{
                                        new Date(scope.row?.to_date?.year, scope.row?.to_date?.month - 1, scope.row?.to_date?.day).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })
                                    }}
                                </div>
                                <div v-else>
                                    {{}}
                                </div>
                            </template>
                        </el-table-column>
                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left">No Date</div>
                        </template>
                    </el-table>
                </div>

                <div v-if="$route.query.type == 'Person'">
                    <div class="head-title" v-if="watchlisrole?.primary?.length > 0">ROLES</div>
                    <div class="head-title" v-if="watchlisrole?.primary?.length > 0">Primary Occupation</div>
                    <div class="persion-table" v-if="watchlisrole?.primary.length > 0">
                        <el-table ref="treeTable" :data="watchlisrole?.primary" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="CATEGORY" :prop="showTranslate == true ? 'category_type' : 'category_type'" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="TITLE" prop="title" align="left" show-overflow-tooltip :resizable="false"></el-table-column>
                            <el-table-column label="FROM" width="150" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                    <div v-if="scope.row?.since?.year > 0">
                                        {{
                                            new Date(scope.row?.since?.year, scope.row?.since?.month - 1).toLocaleDateString('en-US', {
                                                month: 'short',
                                                year: 'numeric',
                                            })
                                        }}
                                    </div>
                                    <div v-else></div>
                                </template>
                            </el-table-column>

                            <el-table-column label="TO" width="150" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                    <div v-if="scope.row?.to?.year > 0">
                                        {{
                                            new Date(scope.row?.to?.year, scope.row?.to?.month - 1).toLocaleDateString('en-US', {
                                                month: 'short',
                                                year: 'numeric',
                                            })
                                        }}
                                    </div>
                                    <div v-else></div>
                                </template>
                            </el-table-column>
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                        <div class="page_i_box"></div>
                    </div>
                    <div class="head-title" v-if="watchlisrole.other">Other Roles</div>
                    <div class="persion-table" v-if="watchlisrole.other">
                        <el-table ref="treeTable" :data="watchlisrole?.other" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="CATEGORY" :prop="showTranslate == true ? 'category_type' : 'category_type'" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="TITLE" prop="title" align="left" show-overflow-tooltip :resizable="false"></el-table-column>
                            <el-table-column width="150" label="FROM" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                    <div v-if="scope.row?.since?.year > 0">
                                        {{
                                            new Date(scope.row?.since?.year, scope.row?.since?.month - 1).toLocaleDateString('en-US', {
                                                month: 'short',
                                                year: 'numeric',
                                            })
                                        }}
                                    </div>
                                    <div v-else>
                                      
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column width="150" label="TO" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                    <div v-if="scope.row?.to?.year > 0">
                                        {{
                                            new Date(scope.row?.to?.year, scope.row?.to?.month - 1, scope.row?.to?.day).toLocaleDateString('en-US', {
                                                month: 'short',
                                                year: 'numeric',
                                            })
                                        }}
                                    </div>
                                    <div v-else>
                                       
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                    </div>
                    <div class="head-title" v-if="watchlisrole.previous">History</div>
                    <div class="persion-table" v-if="watchlisrole.previous">
                        <el-table ref="treeTable" :data="hostrytable" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="CATEGORY" :prop="showTranslate == true ? 'category_type' : 'category_type'" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="TITLE" prop="title" align="left" show-overflow-tooltip :resizable="false"></el-table-column>
                            <el-table-column width="150" label="FROM" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <div >{{scope.row?.since?.year>0}}</div>  -->
                                    <div v-if="scope.row?.since?.year > 0">
                                        {{
                                            new Date(scope.row?.since?.year, scope.row?.since?.month - 1).toLocaleDateString('en-US', {
                                                month: 'short',
                                                year: 'numeric',
                                            })
                                        }}
                                    </div>
                                    <div v-else>
                                 
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column width="150" label="TO" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <div >{{ scope.row.to.month}}</div>  -->
                                    <div v-if="scope.row?.to?.year > 0">
                                        {{
                                            new Date(scope.row?.to?.year, scope.row?.to?.month - 1).toLocaleDateString('en-US', {
                                                month: 'short',
                                                year: 'numeric',
                                            })
                                        }}
                                    </div>
                                    <div v-else>
                                     
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page3.total" @onPageChange="onPageChangeList3" @currtentPageChange="currtentPageChange3"></Page>
                            <div class="search_rsult_txt">{{ page3.pageNo }}-{{ page3.pageSize }} of over {{ page3.total | FilterNum }} results</div>
                        </div>
                    </div>
                </div>
                <div class="head-title">NAMES</div>
                <div>
                    <ul v-if="$route.query.type == 'Person'" class="name-box-head">
                        <li class="information-box-text persion-table-text">FIRST NAME</li>
                        <li class="information-box-text persion-table-text">MIDDLE NAME</li>
                        <li class="hinformation-box-text persion-table-text">SURNAME</li>

                        <li class="information-box-text persion-table-text line_1 tbl_company">ORIGINAL SCRIPT NAME</li>
                        <li class="information-box-text persion-table-text">LANGUAGE</li>
                    </ul>
                    <ul v-else class="name-box-head">
                        <li class="information-box-text persion-table-text1">NAME</li>

                        <li class="information-box-text persion-table-text1">SUFFIX</li>
                        <li class="information-box-text persion-table-text1">ORIGINAL SCRIPT NAME</li>
                        <li class="information-box-text persion-table-text1">LANGUAGE</li>
                    </ul>
                </div>
                <div>
                    <div class="head-title">Primary Name</div>
                    <!-- <div class="information-box-text" style="margin-top: 20px">{{ PrimaryNam.name }}</div> -->
                    <div class="persion-table">
                        <el-table ref="treeTable" v-if="zhicaiTable1" :data="zhicaiTable1" row-key="aaa_id" :show-header="false" style="width: 100%" v-loading="loading">
                            <!-- <el-table-column label="TITLE" prop="TITLE" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link yangsi"></el-table-column> -->
                            <el-table-column label="FIRSTNAME" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div v-if="$route.query.type == 'Person'" class="tbl_company line_1">
                                        {{ scope.row.first_name }}
                                    </div>
                                    <div v-else class="tbl_company line_1">
                                        {{ scope.row.name }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="$route.query.type == 'Person'" label="MIDDLE NAME" :prop="showTranslate == true ? 'source' : 'middle_name'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="surname" :prop="showTranslate == true ? 'surname' : 'surname'" align="left" show-overflow-tooltip :resizable="false" />

                            <!-- <el-table-column label="Gender" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false" /> -->
                            <el-table-column label="orig" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1" v-for="(item, index) in scope.row.associated_names?.original_script_names" :key="index">{{ item.name }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="lenge" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1" v-for="(item, index) in scope.row.associated_names?.original_script_names" :key="index">{{ item.language }}</div>
                                </template>
                            </el-table-column>
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                    </div>
                    <div class="head-title" v-if="Formerlyable2.length > 0">Also Known As</div>
                    <!-- <div class="information-box-text" style="margin-top: 20px">{{ PrimaryNam.name }}</div> -->
                    <div class="persion-table">
                        <el-table ref="treeTable" v-if="Formerlyable2.length > 0" :data="Formerlyable2" row-key="aaa_id" :show-header="false" style="width: 100%" v-loading="loading">
                            <!-- <el-table-column label="TITLE" prop="TITLE" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link yangsi"></el-table-column> -->
                            <el-table-column label="FIRSTNAME" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div v-if="$route.query.type == 'Person'" class="tbl_company line_1">
                                        {{ scope.row.first_name }}
                                    </div>
                                    <div v-else class="tbl_company line_1">
                                        {{ scope.row.name }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="$route.query.type == 'Person'" label="MIDDLE NAME" :prop="showTranslate == true ? 'source' : 'middle_name'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="surname" :prop="showTranslate == true ? 'surname' : 'suffix'" align="left" show-overflow-tooltip :resizable="false" />

                            <!-- <el-table-column label="Gender" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false" /> -->
                            <el-table-column label="orig" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1" v-for="(item, index) in scope.row?.associated_names?.original_script_names" :key="index">{{ item.name }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="lenge" :prop="showTranslate == true ? 'summary' : ''" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1" v-for="(item, index) in scope.row?.associated_names?.original_script_names" :key="index">{{ item.language }}</div>
                                </template>
                            </el-table-column>
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                    </div>
                    <!-- <div class="head-title" v-if="$route.query.type == 'Entity'">ID NUMBERS</div>
                    <div class="persion-table" v-if="$route.query.type == 'Entity'">
                        <el-table ref="treeTable" :data="Numbersable" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="TYPE" :prop="showTranslate == true ? 'type' : 'type'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="NUMBER" :prop="showTranslate == true ? 'value' : 'value'" align="left" show-overflow-tooltip :resizable="false" />

                            <el-table-column label="NOTES" :prop="showTranslate == true ? 'comments' : 'comments'" align="left" show-overflow-tooltip :resizable="false" />

                     
                            <template slot="empty">
                                <div style="text-align: left">Nothing detrimental noted on your search of "xxxxxx"</div>
                            </template>
                        </el-table>
                    </div> -->
                    <div class="head-title" v-if="$route.query.type == 'Person' && person?.places_of_birth?.length > 0">PLACE OF BIRTH</div>
                    <div class="persion-table" v-if="$route.query.type == 'Person' && person?.places_of_birth?.length > 0">
                        <el-table ref="treeTable" v-if="person.places_of_birth" :data="person?.places_of_birth" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="CITY" :prop="showTranslate == true ? 'city' : 'city'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="STATE / REGION" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1">{{ scope.row?.region ? scope.row.region : '' }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="COUNTRY/TERRITORY" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1">{{ scope.row?.country.descriptor ? scope.row?.country.descriptor : '' }}</div>
                                </template>
                            </el-table-column>

                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                    </div>
                    <div class="head-title" v-if="$route.query.type == 'Person' && images.length > 0">IMAGES</div>
                    <div class="persion-table" v-if="$route.query.type == 'Person' && images.length > 0">
                        <div class="information-box-text" v-for="(item, index) in images" :key="index">
                            <a style="color: #1290c9; line-height: 35px" :href="item.external_url" target="_blank" rel="noopener noreferrer">{{ item.external_url }}</a>
                        </div>
                    </div>
                    <!-- 前称 -->
                    <!-- <div class="head-title" v-if="Formerlyable1">Formerly Known As</div>
                    <div class="persion-table">
                        <el-table ref="treeTable" v-if="Formerlyable1" :show-header="false" :data="Formerlyable1" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="" :prop="showTranslate == true ? 'name' : 'name'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="" :prop="showTranslate == true ? 'suffix' : 'suffix'" align="left" show-overflow-tooltip :resizable="false" />

                            <el-table-column label="" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1" v-for="(item, index) in scope.row.associated_names?.original_script_names" :key="index">{{ item.name }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="line_1" v-for="(item, index) in scope.row.associated_names?.original_script_names" :key="index">{{ item.language }}</div>
                                </template>
                            </el-table-column>

                     
                        </el-table>
                    </div> -->
                    <!-- <div class="head-title" v-if="Formerlyable?.spelling_variations">SPELLING VARIATION</div>
                    <div class="persion-table" v-if="Formerlyable?.spelling_variations">
                        <el-table ref="treeTable" :show-header="false" :data="Formerlyable?.spelling_variations" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="" :prop="showTranslate == true ? 'name' : 'name'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="" :prop="showTranslate == true ? 'suffix' : 'suffix'" align="left" show-overflow-tooltip :resizable="false" />

                            <el-table-column label="" prop="" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="" prop="" align="left" show-overflow-tooltip :resizable="false" />

                        
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                    </div> -->
                    <div class="head-title" v-if="addressable.length > 0">ADDRESSES</div>
                    <div class="persion-table" v-if="addressable.length > 0">
                        <el-table ref="treeTable" :data="addressable" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="ADDRESS" :prop="showTranslate == true ? 'address' : 'address'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="CITY" width="150" :prop="showTranslate == true ? 'city' : 'city'" align="left" show-overflow-tooltip :resizable="false" />

                            <el-table-column label="STATE / REGION" :prop="showTranslate == true ? 'region' : 'region'" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="ZIPCODE" width="120" :prop="showTranslate == true ? 'postal_code' : 'postal_code'" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="COUNTRY/TERRITORY" :prop="showTranslate == true ? '' : ''" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.country?.descriptor }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="URL" :prop="showTranslate == true ? 'url' : 'url'" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <a style="color: #1290c9" :href="scope.row.url" target="_blank" rel="noopener noreferrer">{{ scope.row.url }}</a>
                                </template>
                            </el-table-column>
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                    </div>
                    <div class="head-title" v-if="parentTablenum1.length > 0">ID NUMBERS</div>
                    <div class="persion-table" v-if="parentTablenum1.length > 0">
                        <el-table ref="treeTable" :data="Numbersable" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <el-table-column label="TYPE" :prop="showTranslate == true ? 'type' : 'type'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="NUMBER" :prop="showTranslate == true ? 'value' : 'value'" align="left" show-overflow-tooltip :resizable="false" />

                            <el-table-column label="NOTES" :prop="showTranslate == true ? 'comments' : 'comments'" align="left" show-overflow-tooltip :resizable="false" />

                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page1.total" @onPageChange="onPageChangeList1" @currtentPageChange1="currtentPageChange1"></Page>
                            <div class="search_rsult_txt">{{ page1.pageNo }}-{{ page1.pageSize }} of over {{ page1.total | FilterNum }} results</div>
                        </div>
                    </div>
                    <div class="head-title" v-if="tableList.length > 0">RELATIONSHIPS</div>
                    <div class="tbl_search_box" v-if="tableList.length > 0">
                        <div class="tbl_search">
                            <el-input v-model="searchTblName" type="text" clearable suffix-icon="el-icon-search" placeholder="Please enter name" @change="searchPersonByName()" />
                        </div>
                    </div>
                    <div class="persion-table" v-if="tableList.length > 0">
                        <el-table ref="treeTable" :data="Relationstable" row-key="aaa_id" style="width: 100%" v-loading="loading">
                            <!-- <template slot="empty">
                            <noDate />
                        </template> -->
                            <!-- <el-table-column label="" :prop="showTranslate == true ? 'source' : ''" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div class="tbl_company" :class="{ yanse: index == 1, yanse1: index == 0 }" v-for="(item, index) in scope.row.icon_hints" :key="index">{{  item }}</div>
                                </template>
                            </el-table-column> -->

                            <el-table-column label="NAME" :prop="showTranslate == true ? 'summary' : 'NAME'" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                    <div v-if="scope.row.type == 'Person'" style="cursor: pointer; color: #1290c9" @click="sediddetail(scope.row)">{{ scope.row.name_detail.surname }},{{ scope.row.name_detail.first_name }}</div>
                                    <div style="cursor: pointer; color: #1290c9" v-else @click="sediddetail(scope.row)">
                                        {{ scope.row.name_detail.name }}
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column label="TYPE" :prop="showTranslate == true ? 'type' : 'type'" align="left" show-overflow-tooltip :resizable="false" />

                            <el-table-column label="RELATION" :prop="showTranslate == true ? 'connection_type' : 'connection_type'" align="left" show-overflow-tooltip :resizable="false" />
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
                            <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                        </div>
                    </div>
                    <div class="head-title" v-if="$route.query.type == 'Person' && person.associated_entity_details?.associated_entities">ASSOCIATED ENTITIES</div>
                    <div class="persion-table" v-if="$route.query.type == 'Person' && person.associated_entity_details?.associated_entities">
                        <el-table ref="treeTable" v-if="person.associated_entity_details?.associated_entities" :data="asscidtetable" style="width: 100%">
                            <el-table-column label="COMPANY NAME" :prop="showTranslate == true ? 'company_name' : 'company_name'" align="left" show-overflow-tooltip :resizable="false"></el-table-column>

                            <el-table-column label="RELATION" width="150" prop="" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <div >{{ scope.row.from_date.year}}</div>  -->
                                    <div v-if="scope.row">{{ 'Associated Entity' }}</div>
                                </template>
                            </el-table-column>

                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <div style="text-align: left">No Date</div>
                            </template>
                        </el-table>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page2.total" @onPageChange="onPageChangeList2" @currtentPageChange="currtentPageChange2"></Page>
                            <div class="search_rsult_txt">{{ page2.pageNo }}-{{ page2.pageSize }} of over {{ page2.total | FilterNum }} results</div>
                        </div>
                    </div>

                    <!-- <div class="head-title">Profile Notes</div> -->
                    <el-collapse v-if="$route.query.type == 'Entity' || str.length > 0" v-model="activeNames">
                        <el-collapse-item name="1">
                            <template slot="title">
                                <div class="head-title" style="background-color: #fff">Profile Notes</div>
                            </template>
                            <div class="information-box-text" v-html="formattedString"></div>
                        </el-collapse-item>
                        <el-collapse-item name="2">
                            <template slot="title">
                                <div class="head-title" style="background-color: #fff">Sources</div>
                            </template>
                            <div class="information-box-text" v-for="(item, index) in sources" :key="index">
                                <a style="color: #1290c9; line-height: 35px" :href="item.external_link" target="_blank" rel="noopener noreferrer">{{ item.external_link }}</a>
                            </div>
                        </el-collapse-item>
                        <!-- <el-collapse-item title="反馈 Feedback" name="2">
                            <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
                            <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
                        </el-collapse-item> -->
                    </el-collapse>
                </div>
            </div>
            <div class="back-to-top" v-show="showBackToTop" @click="scrollToTop">
                <button class="el-icon-top gaodu"></button>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>
<script>
import { searchDetailsById } from '@/api/searchApi.js';
import Header from '@/components/header';
import Footer from '@/components/footer';
import Page from '@/components/page';
// import noDate from '@/components/tableNoDate';
export default {
    components: { Header, Footer, Page },

    data() {
        return {
            showBackToTop: false,
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            page1: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            page2: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            page3: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            asscidtetable: [],
            searchTblName: '',
            showTranslate: false,
            loading: false,
            lianshu: {},
            activeNames: ['1', '2'],
            Formerlyable1: [],
            str: '',
            active_status: '',
            person: {},
            zhicaiTable1: [
         
            ],

            Numbersable: [],
            tableListnum: [],
            parentTablenum1: [],
            Relationstable: [],
            addressable: [],
            tableList: [],
            parentTable1: [],
            watchlisttable: [],
            Formerlyable: [],
            watchlistothertable: [],
            Formerlyable2: [],
            PrimaryNam: { name: '' },
            zhuce: {},
            zhuce1: {},
            sources: [],
            Wathchlist: [],
            images: [],
            timers: '',
            last_reviewed_date: '',
            watchlisrole: { primary: [] },
            hostrytable: [],
        };
    },
    computed: {
        formattedString() {
            return this.str.replace(/\n/g, '<br>');
        },
    },
    created() {
        // this.getYears()
        this.getdetail();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // 移除页面滚动事件的监听
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            // 判断页面滚动距离是否超过200px，更新showBackToTop的值
            this.showBackToTop = window.pageYOffset > 1000;
        },
        scrollToTop() {
            // 使用scrollTo()方法将页面滚动到顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        sediddetail(row) {
            let nmae = '';
            if (row.type == 'Person') {
                nmae = row.name_detail.surname + ',' + row.name_detail.first_name;
            } else {
                nmae = row.name_detail.name;
            }
            let routeData = this.$router.resolve({
                path: '/search/newsanction?' + '&type=' + row.type + '&id=' + row.profile_id + '&name=' + nmae,
            });

            window.open(routeData.href, '_blank');
        },
        setid(row) {
            if (row.sources[0].includes('http')) {
                let routeData = row.sources[0];
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row.sources[0];
                window.open(routeData, '_blank');
            }
            // let routeData = row.url;
            // window.open(routeData, '_blank');
        },
        pageList() {

            this.Relationstable = this.parentTable1.filter((item, index) => index < this.page.pageNo * this.page.pageSize && index >= this.page.pageSize * (this.page.pageNo - 1));
            this.page.total = this.parentTable1.length;
        },

        onPageChangeList(pageNo) {
            // this.pageNo += 1
            this.page.pageNo = pageNo;
            this.pageList();
        },
        currtentPageChange(pageSize) {
            // this.pageNo += 1
            this.page.pageSize = pageSize;
            // this.init()
            this.pageList();
        },
        pageListasstble() {
            this.asscidtetable = this.person.associated_entity_details?.associated_entities.filter((item, index) => index < this.page2.pageNo * this.page2.pageSize && index >= this.page2.pageSize * (this.page2.pageNo - 1));
            this.page2.total = this.person.associated_entity_details?.associated_entities.length;
        },
        onPageChangeList2(pageNo) {
            // this.pageNo += 1
            this.page2.pageNo = pageNo;
            this.pageListasstble();
        },
        currtentPageChange2(pageSize) {
            // this.pageNo += 1
            this.page2.pageSize = pageSize;
            // this.init()
            this.pageListasstble();
        },
        pageListhostry() {
            if (this.watchlisrole?.previous != undefined) {
                this.hostrytable = this.watchlisrole?.previous.filter((item, index) => index < this.page3.pageNo * this.page3.pageSize && index >= this.page3.pageSize * (this.page3.pageNo - 1));
                this.page3.total = this.watchlisrole?.previous.length;
            }
        },
        onPageChangeList3(pageNo) {
            // this.pageNo += 1
            this.page3.pageNo = pageNo;
            this.pageListhostry();
        },
        currtentPageChange3(pageSize) {
            // this.pageNo += 1
            this.page3.pageSize = pageSize;
            // this.init()
            this.pageListhostry();
        },
        // 人名筛选
        searchPersonByName() {
            let list = this.tableList;
            let keyWord = this.searchTblName;
            var arr = [];
            if (keyWord == '') {
                this.Relationstable = list;
                this.parentTable1 = list;
            } else {
                let that = this;
                arr = list.filter(item => {
                    if (item.type == 'Person') {
                        return item?.name_detail?.surname?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1 || item?.name_detail?.first_name?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                    } else {
                        return item?.name_detail?.name?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                    }
                });
                this.parentTable1 = arr;
            }
            this.pageList();
        },
        pageListid() {

            this.Numbersable = this.parentTablenum1.filter((item, index) => index < this.page1.pageNo * this.page1.pageSize && index >= this.page1.pageSize * (this.page1.pageNo - 1));
            this.page1.total = this.parentTablenum1.length;
        },
        onPageChangeList1(pageNo) {
            // this.pageNo += 1
            this.page1.pageNo = pageNo;
            this.pageListid();
        },
        currtentPageChange1(pageSize) {
            // this.pageNo += 1
            this.page1.pageSize = pageSize;
            // this.init()
            this.pageListid();
        },
        // 人名筛选
        searchPersonByNameid() {
            let list = this.tableListnum;
            let keyWord = this.searchTblName;
            var arr = [];
            if (keyWord == '') {
                this.Numbersable = list;
                this.parentTablenum1 = list;
            } else {
                let that = this;
                arr = list.filter(item => {
                    if (that.$route.query.type == 'Person') {
                        return item?.name_detail?.surname?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1 || item?.name_detail?.first_name?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                    } else {
                        return item?.name_detail?.name?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                    }
                });
                this.parentTablenum1 = arr;
            }
            this.pageListid();
        },
        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                let nums = [];
                let nums1 = [];
                // this.Relationstable=
                // this.page.total=this.Relationstable.length
                if (this.$route.query.type == 'Person') {
                    this.images = res.data.attributes.image ? res.data.attributes.image.image_details : [];
                    this.zhuce = res.data.attributes.person?.date_details ? res.data.attributes.person.date_details?.birth[0].date : '';
                    this.person = res.data.attributes.person ? res.data.attributes.person : {};
                    this.watchlisrole = res.data.attributes.watchlist?.role_details ? res.data.attributes.watchlist.role_details : this.watchlisrole;
                    this.watchlisrole.primary = res.data.attributes?.watchlist?.role_details?.primary ? [res.data.attributes.watchlist?.role_details?.primary] : [];
                } else {
                    this.zhuce = res.data.attributes?.entity.date_details ? res.data.attributes?.entity?.date_details?.registration[0].date : '';
                    this.zhuce1 = res.data.attributes.entity.date_details ? res.data.attributes.entity.country_territory_details.registration[0] : '';
                    // this.Numbersable = res.data.attributes.entity.identification_details;

                    this.watchlistothertable = res.data.attributes?.list_reference?.other_official_lists ? res.data.attributes?.list_reference?.other_official_lists : [];
                }
                nums = res.data.attributes.person?.identification_details ? res.data.attributes.person?.identification_details : [];
                nums1 = res.data.attributes.watchlist?.identification_details ? res.data.attributes.watchlist?.identification_details : [];
                nums = nums.concat(nums1);
                this.parentTablenum1 = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [].concat(nums);
                this.tableListnum = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [];
                this.str = res.data.attributes.watchlist?.comment_details?.list_comment ? res.data.attributes.watchlist.comment_details?.list_comment : '';
                this.sources = res.data.attributes?.watchlist?.sources ? res.data.attributes.watchlist.sources : [];
                // 制裁列表
                this.watchlisttable = res.data.attributes?.list_reference?.sanctions_lists ? res.data.attributes?.list_reference?.sanctions_lists : [];
                // Country/Territory Details
                this.lianshu = res.data.attributes?.watchlist?.country_territory_details ? res.data.attributes.watchlist.country_territory_details : {};
                // watchilsrt内容
                this.Wathchlist = res.data.attributes?.watchlist?.content_type_details ? res.data.attributes.watchlist.content_type_details : {};
                // 主称

                this.PrimaryNam = res.data.attributes.basic.name_details.primary_name;
                this.zhicaiTable1 = [res.data.attributes.basic.name_details.primary_name];
                // 别名
                this.Formerlyable2 = res.data.attributes.basic?.name_details.also_known_as ? res.data.attributes.basic?.name_details.also_known_as : [];
                // 前称
                this.Formerlyable1 = res.data.attributes.basic?.name_details?.formerly_known_as ? res.data.attributes.basic?.name_details?.formerly_known_as : [];
                this.Formerlyable = res.data.attributes.basic?.name_details?.also_known_as ? res.data.attributes.basic.name_details.also_known_as[0].associated_names : [];
                // 地址
               
                // id
                // 制裁名单
                // 档案注解

                // 关系
             
                this.timers = res.data.attributes?.watchlist?.last_reviewed_date ? res.data.attributes.watchlist.last_reviewed_date : '';
                this.parentTable1 = res.data.attributes?.relationship?.connection_details ? res.data.attributes.relationship.connection_details : [];
                this.tableList = res.data.attributes.relationship?.connection_details ? res.data.attributes.relationship?.connection_details : [];
                if (res.data.attributes?.basic?.address_details == undefined) {
                    res.data.attributes.basic = {};
                    res.data.attributes.basic.address_details = [];
                }
                if (res.data.attributes?.watchlist?.address_details == undefined) {
                    res.data.attributes.watchlist = {};
                    res.data.attributes.watchlist.address_details = [];
                }
                this.addressable = res.data.attributes?.basic?.address_details == undefined && res.data.attributes?.watchlist?.address_details == undefined ? [] : res.data.attributes.basic.address_details.concat(res.data.attributes.watchlist.address_details);
                this.pageList();
                this.pageListid();
                this.pageListasstble();
                this.pageListhostry();
            });
        },
    },
};
</script>
<style scoped>
.no_m {
    margin-bottom: 0;
}

.seach-condition /deep/ .el-input-group__prepend {
    background-color: #fff;
    border-radius: 14px 0 0 14px;
}
.seach-condition /deep/ .el-select .el-input .el-input__inner {
    /* background-color: #fff; */
    /* border-radius: 0; */
    border: none;
}
.seach-condition /deep/.el-select .el-icon-arrow-up:before {
    /* border-left: 1px solid #ccc; */
    /* padding-left: 5px; */
    color: #ccc !important;
}
.seach-condition /deep/ .el-input__inner {
    border-radius: 0 14px 14px 0;
    background-color: #fff;
    border-left: none;
    color: #022955 !important;
}

.seach-condition /deep/ .el-input__inner:focus {
    border-color: #ccc;
}
.seach-condition .seach-text {
    line-height: 30px;
    font-size: 14px;
}
.seaction-table {
    margin-top: 50px;
}
.name-head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.head-title {
    color: #022955;
    font-size: 16px;
    line-height: 25px;
    margin-top: 20px;
    background: #f2f5f9;
    font-weight: 700;
    font-family: 'element-icons';
}
.name-information {
    width: 840px;
    display: flex;
    justify-content: space-around;
}
.information-box {
    font-size: 14px;
}
.information-box-text {
    font-size: 14px;
    color: #022955;
    line-height: 25px;
}
.information-box-text1 {
    font-size: 16px;
    /* color: #1290c9; */
    line-height: 25px;
}
.name-overview {
    display: flex;
    justify-content: space-between;
}
.name-overview-box {
    width: 50%;
    padding-right: 20px;
    /* font-size: 15px; */
}
.name-overview-box-title {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.seach-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.name-overview-box-text {
    padding: 10px;
}
.linheight {
    line-height: 20px;
}
.name-box-head {
    display: flex;
    /* justify-content: space-between; */
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    align-items: center;
}
.persion-table {
}
.persion-table-text {
    width: -webkit-fill-available;
    /* width: 260px; */
    padding-left: 10px;
    /* margin-right: 120px; */
}
.persion-table-text1 {
    width: -webkit-fill-available;
    padding-left: 10px;
    /* margin-right: 160px; */
}
.yanse {
    padding: 3px;
    /* background-color: #fac863; */
    margin-right: 4px;
    /* color: #fff; */
}
.yanse1 {
    padding: 3px;
    /* background-color: red; */
    margin-right: 4px;
    /* color: #fff; */
}
.tbl_search_box {
    /* position: absolute;
  left: 0; */
    margin: 20px 0;
    float: right;
}
.title_txt-right {
    display: flex;
}

.tbl_search {
    position: relative;
    width: 290px;
    height: 40px;
    z-index: 9;
}
.tbl_search /deep/ .el-input .el-input__inner {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search .el-input {
    height: 100%;
}
.name-head-title {
    font-size: 16px;
    background-color: #ffffff !important;
    margin-top: 0px;
}
.back-to-top {
    z-index: 2;
    position: fixed;
    transition: opacity 0.4s ease-in-out 0s;
    opacity: 1;
    box-sizing: border-box;
    bottom: 86px;
    right: 45px;
    cursor: pointer;
    /* background-color: #1290c9; */
}

.gaodu {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #1290c9;
    color: #fff;
    font-size: 20px;
}
</style>